export default function submitForm() {
  document.getElementById('form').addEventListener('submit', function(e) {
    e.preventDefault();

    const form = e.target;
    const msgWrapper = document.querySelector('.form__message');

    msgWrapper.innerHTML = '';

    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
    })
    .then(response => response.json())
    .then(data => {
      msgWrapper.innerHTML = `<p>${data.status}</p>`
    })
  })
}
