import get from 'lodash/get';
import langObj from './texts';
import mobileNav from './mobileNav'
import submitForm from './submitForm';
import setSelect from './setSelect';

let ACTIVE_LANG = 'en';

const allDataLang = document.querySelectorAll('[data-lang]');

function setLang() {
  allDataLang.forEach(node => {
    const path = `${ACTIVE_LANG}.${node.dataset.lang}`;
    const text = get(langObj, path);
    node.innerHTML = text;
  });
}

window.addEventListener('DOMContentLoaded', () => {
  setLang();
  mobileNav();
  submitForm();
  setSelect();
});
