export default {
  en: {
    nav: {
      link_1: 'The Mask',
      link_2: 'Get Yours',
      link_3: 'FAQ',
      link_4: 'Disclaimer',
      link_5: 'Contact',
    },
    header: {
      title: 'Transparent<br>Face Mask',
      text: 'Because the future<br>of the mask is clear.<br>SeeU Mask.',
    },
    intro: {
      main_title: 'The Mask',
      first_title: 'SeeU Mask',
      first_text: 'Is a clear, transparent face mask made of FLEXIBLE, anti scratch, soft and comfortable Eco friendly TPU material. Easy to clean and reusable.',
      second_title: 'Design',
      second_text: 'The unique soft flexible design of the mask allows you to fold it and carry easily in your pocket or purse. Our patent pending shape of the mask fits comfortably over your nose and mouth while allowing you to speak and breathe easily. Flexible benefit of the SeeU Mask makes it very comfortable to wear. Adjustable ear straps. One Size fits all.',
      third_title: 'Transparency',
      third_text: 'Anti fog coating. 100% transparent for full facial visibility. Makes lip reading and facial expression easily visible. Allows for clear facial recognition.',
      fourth_text: 'The future of the mask is clear. SeeU Mask!',
    },
    features: {
      main_title: 'Features of SeeU Mask',
      subtitle: 'The unique mask has unique features.<br>It is not only protection, but also lifestyle and complete freedom.',
      icon_1: 'High level<br>of filtration',
      icon_2: 'Face recognition<br>(Face ID)',
      icon_3: 'Therapeutic<br>effect',
      icon_4: 'Non-verbal<br>communication',
      icon_5: 'Easily<br>disinfectable',
      icon_6: 'Anti-fog',
      icon_7: 'Outfit<br>neutral',
      icon_8: 'Biodegradable',
    },
    cards: {
      title_1: 'Advantages of clear',
      text_1: 'Show off your smile and open your phone without taking the mask off.',
      title_2: 'Filter',
      text_2: 'With or without the filter.',
      title_3: 'Face ID',
      text_3: 'Allows for better communication with the hiring impaired. Allows facial recognition with the security systems and your mobile phone.',
      title_4: 'Anti-fog',
      text_4: 'The mask is treated with anti fogging liquid that keeps it clear. It may need to be reapplied over the life time of the mask.',
    },
    form: {
      title: 'Get Yours!',
      text: 'Do you want to know more<br>or place an order for you or your store?<br>Just fill in the contact form and we will get back to you<br>as soon as possible',
      text_2: 'Or give us a call on: +1-561-667-1800 (US)',
      form_title: 'Just fill in and submit',
      select_title: 'Country',
      btn: 'Submit your details',
    },
    faq: {
      title: 'FAQ - Pro Human face mask',
      question_1: '1.Does SeeU Mask fog up?',
      answer_1: 'No it does not.<br>We applied anti-fog treatment to the mask so it stays clear. Depending on the climate and humidity levels in your area you may need to reapply anti fog to the mask periodically.',
      question_2: '2. Does it come in different sizes?',
      answer_2: 'No.<br>Our unique design is one size fits all.',
      question_3: '3. Will my phone facial recognition work while I am wearing the mask?',
      answer_3: 'Yes it will.',
      question_4: '4. What material is used to make this mask?',
      answer_4: 'The mask is made of soft, skin friendly polymer. It’s flexible and fits snuggly to your face.',
      question_5: '5. Is mask material Eco friendly?',
      answer_5: 'Yes.<br>It’s made out of fully biodegradable materials.'
    },
    disclaimer: {
      title: 'Disclaimer',
      text_1: 'Medical or surgical use prohibited. This is not medical-grade personal protective equipment.<br>Wearing a face cover might provide some degree of protection, but it does not replace precautions like social distancing and thoroughly washing your hands.',
      text_2: 'Mask for adults, suitable for daily washing in warm water with dish soap or hand soap and easy disinfection. Mask transparency may vary depending on weather conditions and level of humidity. Wash hands before putting on.',
    },
    footer: {
      text: 'SeeU Mask, Adress - Registered in UE',
      links: {
        terms: 'Terms & Conditions',
        policy: 'Privace policy',
        info: 'Company info',
      },
    },
  },
  pl: {},
};
