export default function mobileNav() {
  const hamburger = document.querySelector('.hamburger');
  const nav = document.querySelector('nav');

  hamburger.addEventListener('click', function() {
    this.classList.toggle('is-active');
    nav.classList.toggle('is-active');
  });

  nav.addEventListener('click', function(e) {
    if (e.target.tagName === 'A') {
      if (nav.classList.contains('is-active')) {
        nav.classList.remove('is-active');
      }
      if (hamburger.classList.contains('is-active')) {
        hamburger.classList.remove('is-active');
      }
    }
  });
}